import { createSlice } from '@reduxjs/toolkit';

const tokenSlice = createSlice({
    name: 'token',
    initialState: {
        value: localStorage.getItem('token'), // Ajoutez une valeur par défaut pour le token
    },
    reducers: {
        sendToken: (state, action) => {
            state.value = action.payload;
        },
    },
});

export const { sendToken } = tokenSlice.actions;

export default tokenSlice.reducer;