// import React, { useState, useEffect } from "react";
import "./Navbar.css";
import { Link } from "react-router-dom";

export default function Header(props) {
    
    return (
        <div className="navbar">
            <div>
                <Link to='/profil'> <h4>Mon Profil</h4> </Link>
                <Link to='/vehicule'> <h4>Mes Véhicules</h4> </Link>
                <Link to='/service'> <h4>Mes Services</h4> </Link>
            </div>
            {props.children}
        </div>
    );
}
