import {useState, useEffect} from 'react'
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Notif from '../../Components/Notif/Notif';
import axios from 'axios';
import './Vehicle.css'

export default function Vehicle(){

    const navigate = useNavigate();

    const token = useSelector((state) => state.token.value);

    const [notification, setNotification] = useState(null);

    const closeNotification = () => {
        setNotification(null);
    };

    // Définir l'état pour les données de profil et de véhicule
    const [vehicleData, setVehicleData] = useState([]);


    const [serialNumbers, setSerialNumbers] = useState({});
    const [confirmedSerialNumbers, setConfirmedSerialNumbers] = useState({});

    const handleSerialInputChange = (vehicleId, value) => {
        setSerialNumbers({
            ...serialNumbers,
            [vehicleId]: value,
        });
    };

    const handleConfirmedSerialInputChange = (vehicleId, value) => {
        setConfirmedSerialNumbers(prevState => ({ ...prevState, [vehicleId]: value }));
    };
    

    useEffect(() => {
        const fetchData = async () => {
            try {

                const responsevehicle = await axios.get('https://server.sikomobility.com/api/profile/vehicle', {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Accept': 'application/json',
                    },
                });

                setVehicleData(responsevehicle.data.vehicles);

                console.log(responsevehicle.data);

            } catch (error) {
            }
        };

        fetchData();
    }, [token]); // Ajouter token comme dépendance
        
    const handleSubmitSerialNumber = async (vehicleId) => {
        if (serialNumbers[vehicleId] !== confirmedSerialNumbers[vehicleId]) {
            setNotification({
                type: 'error',
                message: 'Les numéros de série ne correspondent pas.',
            });
            return;
        }
    
        try {
            await axios.patch(`https://server.sikomobility.com/api/profile/vehicle`, {
                id: vehicleId,
                SerialNumber: serialNumbers[vehicleId],
            }, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Accept': 'application/json',
                },
            });
    
            setNotification({
                type: 'success', 
                message: 'Le numéro de série a bien été enregistré',
            });
            
        } catch (error) {
            console.error(error);
        }
    };
    

    return (
        <>
            <Notif
                type={notification && notification.type}
                message={notification && notification.message}
                onClick={closeNotification}
            />

            <div className='Content'>
                {/* Afficher la liste des véhicules */}
                {vehicleData.length > 0 && (
                    <div className='VehicleDiv'>
                        <h2>Véhicules</h2>
                            {vehicleData.map(vehicle => (
                                <div className='VehicleContent'  key={vehicle.id}>
                                    <div className='VehicleGrid'>

                                        <div>
                                            <h4> Marque </h4>
                                            <p>{vehicle.Brand}</p>
                                        </div>

                                        <div>
                                            <h4> Modèle </h4>
                                            <p>{vehicle.Model}</p>
                                        </div>

                                        <div>
                                            <h4> Prix </h4>
                                            <p>{vehicle.Price} €</p>
                                        </div>

                                        <div>
                                            <h4> Type du Vehicule </h4>
                                            <p>{vehicle.VehicleType}</p>
                                        </div>

                                        <div>
                                            <h4> Date d'obtention </h4>
                                            <p>{vehicle.OrderDate}</p>
                                        </div>

                                        {vehicle.SerialNumber ? (
                                            <div>
                                                <h4> Numéro de série </h4>
                                                <p>{vehicle.SerialNumber}</p>
                                            </div>
                                        ) : (
                                            <>
                                            
                                            </>
                                        )}
                                        
                                    </div>

                                    
                                        {vehicle.SerialNumber ? (
                                            <>
                                            
                                            </>
                                        ) : (
                                                <div className='VehicleGrid'>

                                                    <div className="inputDiv">
                                                        <input 
                                                            type="text"
                                                            value={serialNumbers[vehicle.id] || ""}
                                                            onChange={(e) => handleSerialInputChange(vehicle.id, e.target.value)}
                                                        />
                                                        <span></span>
                                                        <label htmlFor="ShippingAddress">Entrez le numéro de série</label>
                                                    </div>

                                                    <div className="inputDiv">
                                                        <input 
                                                            type="text"
                                                            value={confirmedSerialNumbers[vehicle.id] || ""}
                                                            onChange={(e) => handleConfirmedSerialInputChange(vehicle.id, e.target.value)}
                                                        />
                                                        <span></span>
                                                        <label htmlFor="ConfirmedSerialNumber">Confirmer le numéro de série</label>
                                                    </div>
                                                    
                                                    <button className='buttonTall' onClick={() => handleSubmitSerialNumber(vehicle.id)}>Envoyer</button>

                                                </div>

                                        )}
                                </div>
                            ))}
                    </div>
                )}
            </div>
        </>
        
    )
}
