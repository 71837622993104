import './App.css';
import { Routes ,Route } from 'react-router-dom';
import Home from "./Containers/Home/Home";
import Error from "./Containers/Error/Error";
import Profil from "./Containers/Profil/Profil";
import Vehicle from './Containers/Vehicle/Vehicle';
import Service from './Containers/Service/Service';
import Footer from './Components/Footer/Footer';
import Header from './Components/Header/Header';
import Navbar from './Components/Navbar/Navbar';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Navigate } from 'react-router-dom';

function App() {

    const isLoggedIn = useSelector((state) => state.token.value !== null)

    const navigate = useNavigate();

    const deco = () =>{

        localStorage.removeItem('token');

        navigate('/');

        window.location.reload();

    }

    return (
        <div className={ isLoggedIn ? "App GridApp" :  "App"}>
            <Header/>
            { isLoggedIn ?
                <Navbar>
                    <button className= {isLoggedIn ? 'button' : 'buttonOff'} onClick={deco}>Déconnexion</button>
                </Navbar> : <></>
            } 
            <Routes>
                <Route path="/" element={ isLoggedIn ? <Navigate to="/profil" /> : <Home />}/>
                <Route path="/profil" element={isLoggedIn ? <Profil /> : <Navigate to="/" />}/>
                <Route path="/vehicule" element={isLoggedIn ? <Vehicle /> : <Navigate to="/" />}/>
                <Route path="/service" element={isLoggedIn ? <Service /> : <Navigate to="/" />}/>
                <Route path="*" element={<Error />}/>
            </Routes>
            <Footer/> 
        </div>
    );
}

export default App;
