// import React, { useState, useEffect } from "react";
import "./Notif.css";

export default function Notif({ type, message, onClick }) {
    
    return (
        <div className={`Notif ${type}`} onClick={onClick}>
            <h3>
                {message}
            </h3>
        </div>
    );
}
