import {useState, useEffect} from 'react'
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Notif from '../../Components/Notif/Notif';
import axios from 'axios';
import './Service.css'

export default function Service(){
    
    const navigate = useNavigate();

    const token = useSelector((state) => state.token.value);

    const [notification, setNotification] = useState(null);

    const closeNotification = () => {
        setNotification(null);
    };

    // Définir l'état pour les données de profil et de véhicule
    const [serviceData, setserviceData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {

                const responseservice = await axios.get('https://server.sikomobility.com/api/profile/vehicle', {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Accept': 'application/json',
                    },
                });

                setserviceData(responseservice.data.vehicles);

                console.log(responseservice.data);

            } catch (error) {
            }
        };

        fetchData();
    }, [token]); // Ajouter token comme dépendance
        
    function getServiceType(value) {
        switch (value) {
            case 0: return "Pas de service";
            case 1: return "Formule Standard";
            case 2: return "Formule Premium";
            default: return "";
        }
    }
      
    function handleButtonClick(vehicleId, serviceType) {
        console.log("ID du véhicule:", vehicleId, "Type de service:", serviceType);
    }

    function renderButton(vehicleId, serviceValue) {
        const serviceType = getServiceType(serviceValue);
        if (serviceType !== "Pas de service") {
            return (
                <button className='button' onClick={() => handleButtonClick(vehicleId, serviceType)}>
                    Resilier
                </button>
            );
        }
        return null;
    }

    return (
        <>
            <Notif
                type={notification && notification.type}
                message={notification && notification.message}
                onClick={closeNotification}
            />
      
            <div className='Content'>
                {serviceData.length > 0 && (
                    <div className='ServiceDiv'>
                        <h2>Services</h2>
                        {serviceData.map(vehicle => (
                            <div className='ServiceContent' key={vehicle.id}>
                                <h3>{vehicle.VehicleType} - {vehicle.Brand} {vehicle.Model}</h3>
                                <div className='ServiceGrid'>
                                    <div className='ServiceFlex'>
                                        <div>
                                            <h4>Assurance </h4>
                                            <p>{getServiceType(vehicle.InsuranceContract)}</p>
                                        </div>
                                        {/* {renderButton(vehicle.id, vehicle.InsuranceContract)} */}
                                    </div>
                                    <div className='ServiceFlex'>
                                        <div>
                                            <h4>Assistance </h4>
                                            <p>{getServiceType(vehicle.SupportContract)}</p>
                                        </div>
                                        {/* {renderButton(vehicle.id, vehicle.SupportContract)} */}
                                    </div>
                                    <div className='ServiceFlex'>
                                        <div>
                                            <h4>Garantie</h4>
                                            <p>{getServiceType(vehicle.WarrantyContract)}</p>
                                        </div>
                                        {/* {renderButton(vehicle.id, vehicle.WarrantyContract)} */}
                                    </div>
                                    <div className='ServiceFlex'>
                                        <div>
                                            <h4>Maintenance </h4>
                                            <p>{getServiceType(vehicle.ReviewContract)}</p>
                                        </div>
                                        {/* {renderButton(vehicle.id, vehicle.ReviewContract)} */}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </>
    );

}
