import icon from "../../Asset/svg/icon.svg"
import {useState , useEffect} from 'react'
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { sendToken } from '../../Redux/slice';
import axios from 'axios';
import Notif from '../../Components/Notif/Notif'
import './Home.css'

function Home() {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [notification, setNotification] = useState(null);

    const [datalog, setDatalog] = useState({
        Email: "",
        Password: "",
    });

    const linkedInput = (name, value) => {
        setDatalog((prevDatalog) => ({
            ...prevDatalog,
            [name]: value,
        }));
    };

    const closeNotification = () => {
        setNotification(null);
    };

    const handleLogin = async (e) => {

        e.preventDefault(); // Empêche le rechargement de la page lors de la soumission du formulaire

        try {
            const response = await axios.post('https://server.sikomobility.com/api/login', datalog);
            localStorage.setItem('token', response.data.token);
            dispatch(sendToken(response.data.token));
            navigate('/profil');
        } catch (error) {
            alert("L’adresse e-mail que vous avez saisi(e) n’est pas associé à un compte ou le mot de passe est incorrect.");
            // setNotification({ type: 'error', message: "erreur" });
        }
    };

    return (
        <div className='Content Login'>

            <Notif
                type={notification && notification.type}
                message={notification && notification.message}
                onClick={closeNotification}
            />

            <form onSubmit={handleLogin}>

                <img id='icon' src={icon} alt="icon" />
                <div className="inputDiv" >
                    <input type="text" id="Email" name="Email" autoComplete="Email" onChange={e => linkedInput(e.target.name, e.target.value)} value={datalog.Email}  required />
                    <span></span>
                    <label htmlFor="Email">Email</label>
                </div>

                <div className="inputDiv">
                    <input type="password" id="Password" name="Password" autoComplete="current-password" onChange={e => linkedInput(e.target.name, e.target.value)} value={datalog.Password} required />
                    <span></span>
                    <label htmlFor="Password">Mot de passe</label>
                </div>
                <div className='buttonDiv'>
                    <button className="button" type="submit">Se connecter</button>
                </div>
            </form>
        </div>
    );
}

export default Home;
