// import React, { useState, useEffect } from "react";
import "./Header.css";
import { Link } from "react-router-dom";
import logo from "../../Asset/svg/logoblack.svg";

export default function Header(props) {
    
    return (
        <div className="header">
            <Link to='/'><img src={logo} alt="logo"/></Link>
            {props.children}
        </div>
    );
}
