import {useState, useEffect} from 'react'
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Notif from '../../Components/Notif/Notif';
import axios from 'axios';
import './Profil.css'

export default function Profil(){

    const navigate = useNavigate();

    const token = useSelector((state) => state.token.value);

    const [notification, setNotification] = useState(null);

    const closeNotification = () => {
        setNotification(null);
    };

    // Définir l'état pour les données de profil et de véhicule
    const [profileData, setProfileData] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const responseprofile = await axios.get('https://server.sikomobility.com/api/profile', {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Accept': 'application/json',
                    },
                });


                setProfileData(responseprofile.data);

                console.log(responseprofile.data);

            } catch (error) {
            }
        };

        fetchData();
    }, [token]); // Ajouter token comme dépendance
        
    

    return (
        <>
            <Notif
                type={notification && notification.type}
                message={notification && notification.message}
                onClick={closeNotification}
            />

            <div className='Content'>
                {/* Afficher les données de profil */}
                {profileData && (
                    <div className='ProfilDiv'>
                        <h2>Profil</h2>

                        <div className='ProfilGrid'>

                            <div>
                                <h4> Email </h4>
                                <p>{profileData.Email}</p>
                            </div>

                            <div>
                                <h4> Prénom </h4>
                                <p>{profileData.FirstName}</p>
                            </div>

                            <div>
                                <h4> Nom </h4>
                                <p>{profileData.LastName}</p>
                            </div>

                            <div>
                                <h4> Adresse </h4>
                                <p>{profileData.Address}</p>
                            </div>

                            <div>
                                <h4> Code postal </h4>
                                <p>{profileData.ZipCode}</p>
                            </div>

                            <div>
                                <h4> Ville </h4>
                                <p>{profileData.City}</p>
                            </div>

                            <div>
                                <h4> Numéro de téléphone </h4>
                                <p>{profileData.PhoneNumber}</p>
                            </div>

                            <div>
                                <h4> Date de naissance </h4>
                                <p>{profileData.BirthDate}</p>
                            </div>

                        </div>

                        
                        
                    </div>
                )}

            </div>
        </>
        
    )
}
